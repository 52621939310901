<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Letzte 10 Kampagnen</span>
    </v-card-title>
    <v-card-text>
      <v-list class="pt-0 mt-n1">
        <v-list-item
          v-for="(data,index) in data.campaigns"
          :key="data.avatar"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-3':''}`"
          border
          outline

        >
          <v-list-item-avatar size="38">
            <v-avatar
              :color="data.avatar ? '' : data.avatarColor"
              :class="`v-avatar-light-bg info--text`"
              size="30"
            >
              <v-icon size="18" v-if="data.adMaterial.format == 'maxipostkarte'" class="info--text">
                {{ icons.mdiCardBulletedOutline  }}
              </v-icon>
              <v-icon size="18" v-if="data.adMaterial.format == 'dina4mailing'" class="info--text">
                {{ icons.mdiCardBulletedOutline  }}
              </v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <div class="d-flex align-center flex-wrap text-no-wrap flex-grow-1">
            <div>
              <v-list-item-title class="text-sm font-weight-medium">
                <router-link :to="`/kampagne?filter[searchString]=${data.name}`" tag="div" style="cursor: pointer">
                  ID: {{ data.idPtKampagne }}, {{ data.name }}
                </router-link>
              </v-list-item-title>
              <div class="d-flex align-center">

                <v-avatar
                  :color="data.avatar ? '' : data.avatarColor"
                  :class="`v-avatar-light-bg info--text me-1`"
                  size="14"
                >
                  <v-icon size="18" v-if="data.targetGroup.datenquelle == 'bestandsdaten'" class="info--text">
                    {{ icons.mdiBullseyeArrow }}
                  </v-icon>
                  <v-icon size="18" v-if="data.targetGroup.datenquelle == 'eigenerUpload'" class="info--text">
                    {{ icons.mdiCloudUpload }}
                  </v-icon>
                  <v-icon size="18" v-if="data.targetGroup.datenquelle == 'fremderUpload'" class="info--text">
                    {{ icons.mdiShareVariantOutline }}
                  </v-icon>

                </v-avatar>



                <v-list-item-subtitle class="text-xs">
                  ID: {{ data.idPtZielgruppe }}, {{ data.targetGroup.titel }}
                </v-list-item-subtitle>
              </div>
            </div>
            <v-spacer></v-spacer>

            <v-chip
              small
              :class="`v-chip-light-bg info--text font-weight-semibold mr-1`"
            >
              Empfänger: {{data.maxEmpfaenger}}
            </v-chip>

            <template v-if="data.status == 'inBearbeitung'">

              <router-link :to="'/kampagne/editieren/'+data.idPtKampagne" >
                <v-chip
                  small
                  :color="getCampaignStateColor(data.status)"
                  :class="`v-chip-light-bg ${getCampaignStateColor(data.status)}--text font-weight-semibold cursor-pointer`"
                  :title="getCampaignStateDescription(data.status)"
                >
                  <v-icon size="14" color="secondary" class="mr-2">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>

                  {{ getCampaignStateText(data.status) }}
                </v-chip>
              </router-link>

            </template>

            <template v-else>
              <v-chip
                small
                :color="getCampaignStateColor(data.status)"
                :class="`v-chip-light-bg ${getCampaignStateColor(data.status)}--text font-weight-semibold`"
                :title="getCampaignStateDescription(data.status)"
              >
                {{ getCampaignStateText(data.status) }}
              </v-chip>
            </template>

          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import useHelper from "@/views/useHelper";
import {onMounted, ref} from '@vue/composition-api'
import axios from "axios";
import {
  mdiPencilOutline,
  mdiBullseyeArrow,
  mdiCloudUpload,
  mdiShareVariantOutline,
  mdiCardBulletedOutline ,
  mdiCalendarBlankOutline ,
} from "@mdi/js";

export default {
  setup() {

    const data = ref({
      idPtKampagne : 0,
      targetGroup :{
        titel : "",
        datenquelle : "bestandsdaten"
      }
    });

    const tableColumnHeaders = [
      {
        text: '#ID',
        align: 'start',
        value: 'idPtKampagne',
        sortable : false
      },
      { text: 'Kampagne', value: 'kampagneName' , sortable : false},
      { text: 'Status', value: 'status' , sortable : false},
      { text: 'Zielgruppe', value: 'zielgruppe' , sortable : false},
      { text: 'Empfänger', value: 'maxEmpfaenger' , sortable : false, align: 'right'},
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const {
      getCampaignStateColor,
      getCampaignStateDescription,
      getCampaignStateText
    } = useHelper()

    onMounted(()=>{
      axios.post('/api/dashboard/', {
        aktion: 'fetchLastCampaigns',
      })
        .then((response) => {
          data.value = response.data;
        })
        .catch((error) => {
          console.log(error)
        })
    })

    return {
      data,
      tableColumnHeaders,
      getCampaignStateColor,
      getCampaignStateDescription,
      getCampaignStateText,
      icons: {
        mdiPencilOutline,
        mdiBullseyeArrow,
        mdiCloudUpload,
        mdiShareVariantOutline,
        mdiCardBulletedOutline ,
        mdiCalendarBlankOutline ,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  max-width: 130px;
}
.statistics-table {
  border-top: solid 1px rgba(93, 89, 98, 0.14);
  .badge-sm {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    margin: 4px;
  }
}

.noLinkStyles{
  text-decoration: none;
  color:black;
}

.v-list {
  padding: 0;
}
.cursor-pointer{
  cursor: pointer;
}

.v-list-item {
  padding: 10px !important;
  border: 0.1px solid #00000012;
}
</style>
